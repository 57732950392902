import React, { useEffect, useState } from 'react';
import { Card, Page, TextStyle, Badge, ButtonGroup, Button, DisplayText, Icon } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import EmptyOffer from '../EmptyOffer';
import Loader from '../../common/Loader';
import {
	PlusMinor,
	LockMajorMonotone
} from '@shopify/polaris-icons';
import { redirectPage, upgradePlans, getStoreName } from '../../common/Helper';
import { useGlobalPlanContext } from '../../context/GlobalContext';


export default function TabsExample() {
	const shop = getStoreName();
	const [statusLoading, setStatusLoding] = useState(null);
	const [deleteLoding, setDeleteLoding] = useState(null);
	const [upsells, setUpSells] = useState([]);
	const [skeletonLoader, setSkeletonLoader] = useState(true);
	const [toolTip, setTooltip] = useState({
		active: false,
		content: null,
		error: false
	});
	const [activeUpsellsLimit, setActiveUpsellsLimit] = useState(-1);
	const [activeUpsells, setActiveUpsells] = useState(0);
	const plan = useGlobalPlanContext();
	// get offers from db
	useEffect (() => {
		if (plan) {
			setActiveUpsellsLimit(plan.active_upsells);
		}
	}, [plan])
	useEffect(() => {
		/* API.get(`plans/active/plan`).then(response => {
			setActiveUpsellsLimit(response.data.data.active_upsells);
		}).catch(err => {
			console.log('err: ', err);
		}); */
		API.get(`incart-upsell/fetch`).then(response => {
			setUpSells(response.data.data);
			const data = response.data.data;
			const activeUpsells = data.filter(x => x.upsellStatus === 1);
			setActiveUpsells(activeUpsells.length);
			setSkeletonLoader(false);
		}).catch(err => {
			console.log('err: ', err);
			setSkeletonLoader(false);
		});
	}, [shop]);

	const deleteOffer = (id) => {
		setDeleteLoding(id);
		API.delete(`incart-upsell/${id}`).then(response => {
			setTooltip({
				content: "Offer Deleted Successfully",
				active: true,
				error: false
			});
			const sells = [...upsells];
			const newSells = sells.filter(x => x.id !== id);
			setUpSells(newSells);
			setDeleteLoding(null);
		}).catch(err => {
			setDeleteLoding(null);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	};

	const changeStatus = (upsellId) => {
		setStatusLoding(upsellId);
		const allUpsells = [...upsells];
		const index = allUpsells.findIndex(x => x.id === upsellId);
		const url = allUpsells[index].upsellStatus === 0 ? 'activate' : 'deactivate';
		API.post(`incart-upsell/${url}/${upsellId}`).then(response => {
			allUpsells[index].upsellStatus = allUpsells[index].upsellStatus === 0 ? 1 : 0;
			setUpSells(allUpsells);
			setTooltip({
				content: "Status Changed Successfully",
				active: true,
				error: false
			});
			setStatusLoding(null);
		}).catch(err => {
			setStatusLoding(null);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	};

	const offerStatusButtons = (offer) => {
		// const activeUpsellsLimit = plan.active_upsells;
		if (activeUpsellsLimit !== -1 && offer.upsellStatus === 0 && activeUpsells >= activeUpsellsLimit) {
			return (
				<div className="upgrade-plan-button">
					<Button onClick={e => upgradePlans(shop)} icon={<Icon source={LockMajorMonotone} color="blueDark" />}>Upgrade your plan to activate additional upsells</Button>
				</div>
			);
		}
		return (
			<Button onClick={e => changeStatus(offer.id)} loading={statusLoading === offer.id ? true : false}>
				{offer.upsellStatus === 1 ? 'Deactivate' : 'Activate'}
			</Button>
		);
	};

	return (
		<div className='upsells-outr'>
			<TabHeader />
			<Page
				title="Incart Upsells"
				primaryAction={
					<Button primary icon={<Icon source={PlusMinor} />} onClick={e => redirectPage('create-incart')}>
						Create Upsell
    				</Button>
				}
			>
				{upsells.map((offer, index) => {
					const offerMetadata = JSON.parse(offer.upsellOptions);
					return (
						<Card sectioned key={index}>
							<DisplayText size="small">
								{offer.upsellName}
								{offer.upsellStatus === 1 && <Badge status="success">Active</Badge>}
							</DisplayText>
							<TextStyle variation="subdued">{offerMetadata.promotionalDesktopText_meta}</TextStyle>
							<div className="button-flex-wrap m-t-10">
								<ButtonGroup>
									{offerStatusButtons(offer)}
									{offer.upsellStatus !== 1 &&
										<Button primary onClick={e => redirectPage(`edit-incart/${offer.id}`)}>Edit</Button>
									}
									{offer.upsellStatus !== 1 &&
										<Button destructive onClick={e => deleteOffer(offer.id)} loading={deleteLoding === offer.id ? true : false}>
											Delete
											</Button>
									}
									<Button primary onClick={e => redirectPage(`incart-analytics/${offer.id}`)}>Analytics</Button>
								</ButtonGroup>
							</div>
						</Card>
					)
				})}
				{upsells.length === 0 && skeletonLoader === false &&
					<EmptyOffer url="create-incart" buttonText="Create Upsell" />
				}
				{skeletonLoader &&
					<Loader />
				}
				<Tooltip tooltip={toolTip} />
			</Page>
		</div>
	);
}

