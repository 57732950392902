import React, { useState, useEffect } from 'react';
import { Card, Page, Select, TextField, Form, Stack, FormLayout, Thumbnail, Button, ResourceList, RangeSlider, Checkbox, Banner, TextStyle, Subheading } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import ColorChoser from '../../common/Color';
import { colorWithoutHash, buttonOptions, recommendOptions, dummyImage, getPayload, makingFields, recommendationLocations, recommendationPosition, buttonSizeOptions, redirectPage, getStoreName } from '../../common/Helper';
import ItemPicker from '../../common/ItemPicker';
import { ResourcePicker } from '@shopify/app-bridge-react';
import { useParams } from "react-router-dom";
import Tooltip from '../../common/Tooltip';
import API from '../../service/Api';
import FontFamilyPicker from '../../common/FontPicker';
import CssHelper from '../../common/CssHelper';
import '../../assets/recommendation.css';
import RecommendationPreview from './recommendation-preview';
import BackButton from '../../common/BackButton';
import TabSettings from '../../common/TabSettings';
const htmlCode = '<div id="recommendation_offers"></div>';

let initialStates = {
    offerType: 'cart-limit',
    offerName: '',
    cartMin: '',
    cartMax: '',
    status: 0,
    limited: false,
    enableCustomCss: false,
    customCss: '',
    productsInRow: 3,
    recommendationText: 'Recommendations',
    recommendOtherName: '',
    recommendationTextColor: '#000000',
    recommendationFontFamily: '',
    recommendationFontSize: 24,
    saleBadge: false,
    productTitleFontSize: 15,
    productTitleTextColor: '#000000',
    productTitleFontFamily: '',
    productPriceTextColor: '#000000',
    productPriceFontFamily: '',
    productPriceFontSize: 15,
    imageSizeDesktop: 'medium',
    imageSizeMobile: 'medium',
    description: '',
    descriptionFontSize: 15,
    descriptionTextColor: '#000000',
    descriptionFontFamily: '',
    buttonText: 'Add To Cart',
    buttonOtherName: '',
    buttonTextColor: '#ffffff',
    buttonBgColor: '#6371c7',
    buttonFontFamily: '',
    buttonFontSize: 15,
    recommendationLocation: 'top',
    recommendationPosition: 'below-cart',
    productSpacing: 0,
    buttonSizeDesktop: 'medium',
    buttonSizeMobile: 'medium',
    recommendationMobileTextColor: '#000000',
    recommendationMobileFontFamily: '',
    recommendationMobileFontSize: 24,
    descriptionMobileTextColor: '#000000',
    descriptionMobileFontFamily: '',
    descriptionMobileFontSize: 15,
    productTitleMobileTextColor: '#000000',
    productTitleMobileFontFamily: '',
    productTitleMobileFontSize: 15,
    productPriceMobileTextColor: '#000000',
    productPriceMobileFontFamily: '',
    productPriceMobileFontSize: 15,
    productMobileSpacing: 0,
    buttonMobileTextColor: '#ffffff',
    buttonMobileBgColor: '#6371c7',
    buttonMobileFontSize: 15,
    buttonMobileFontFamily: '',
    buttonMobileText: 'Add To Cart',
    buttonMobileOtherName: '',
    productTag: ''
};

function Recommendation() {
    const shop = getStoreName();
    const { id } = useParams();
    const [title, setTitle] = useState("Create New Recommendation");
    const [fields, setFields] = useState(initialStates);
    const [inputErrors, setInputErrors] = useState({
        offerNameError: null,
        offerDecpError: null,
        discountError: null,
        cartLimitError: null,
        productError: null,
        collectionError: null,
        offerProductError: null,
        imageHeightMobileError: null,
        imageWidthMobileError: null,
        imageWidthDesktopError: null,
        imageHeightDesktopError: null,
        productTagError: null,
        recommendOtherNameError: null,
        buttonOtherNameError: null,
        buttonMobileOtherNameError: null
    });

    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });

    const [openPicker, setOpenPicker] = useState(false);
    const [selectedItem, setSelectedItem] = useState('required-product');
    const [requiredProduct, setRequiredProduct] = useState([]);
    const [offerProducts, setOfferProducts] = useState([]);
    const [requiredCollection, setRequiredCollection] = useState(null);
    // selected ids
    const [requiredProductId, setRequiredProductId] = useState([]);
    const [offerProductIds, setOfferProductIds] = useState([]);
    const [requiredCollectionId, setRequiredCollectionId] = useState(null);
    const [copyButtonText, setButtonText] = useState('Copy code');
    const [disableBackButton, setDisableBackButton] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    /**get data from db */
    useEffect(() => {
        if (id) {
            API.get(`recommendation/fetch/${id}`).then(response => {
                const offer = response.data.data.baroffer;
                setTitle(offer.offerName);
                const metaData = JSON.parse(offer.recommendationOptions);
                const buttonName = buttonOptions.find(x => x.value === metaData.buttonText_meta);
                const buttonMobileName = buttonOptions.find(x => x.value === metaData.buttonMobileText_meta);
                const recommedName = recommendOptions.find(x => x.value === metaData.recommendationText_meta);
                let dbFields = makingFields(metaData);
                dbFields.offerName = offer.offerName;
                dbFields.status = offer.offerStatus;
                dbFields.offerType = offer.offerType;
                dbFields.recommendationText = recommedName ? metaData.recommendationText_meta : 'other';
                dbFields.buttonText = buttonName ? metaData.buttonText_meta : 'other';
                dbFields.buttonMobileText = buttonMobileName ? metaData.buttonMobileText_meta : 'other';
                dbFields.imageSizeDesktop = dbFields.imageSizeDesktop ?? 'medium';
                dbFields.imageSizeMobile = dbFields.imageSizeMobile ?? 'medium';
                //make initialStates with db values
                initialStates = dbFields;
                setFields(dbFields);
                if (metaData.product_meta && metaData.product_meta.length) {
                    setRequiredProduct(metaData.product_meta);
                    const ids = [];
                    metaData.product_meta.forEach(item => {
                        ids.push({
                            id: item.id
                        })
                    })
                    setRequiredProductId(ids);
                }
                if (metaData.offerType === 'collection-only') {
                    setRequiredCollectionId([{
                        id: metaData.collection_meta.id,
                    }]);
                    setRequiredCollection(metaData.collection_meta);
                }
                if (metaData.offerProducts_meta && metaData.offerProducts_meta.length) {
                    setOfferProducts(metaData.offerProducts_meta);
                    const ids = [];
                    metaData.offerProducts_meta.forEach(item => {
                        ids.push({
                            id: item.id
                        })
                    })
                    setOfferProductIds(ids);
                }
            });
        }
    }, [id, shop]);

    useEffect(() => {
        if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
            setDisableBackButton(false);
        } else {
            setDisableBackButton(true);
        }
    }, [fields]);

    const handleInputChange = (inputValue, inputName) => {
        setFields({
            ...fields,
            [inputName]: inputValue
        })
    };

    const handleColorChange = (color, inputName) => {
        const value = color.search('#') >= 0 ? color : '#' + color;
        setFields({
            ...fields,
            [inputName]: value
        });
    };

    const handleItemSelection = (resources) => {

        const idFromResources = resources.selection.map((item) => item.id);
        if (idFromResources.length) {
            if (selectedItem === 'required-product') {
                const ids = [];
                idFromResources.forEach(item => {
                    ids.push({
                        id: item
                    })
                })
                setRequiredProductId(ids);
                setInputErrors({
                    ...inputErrors,
                    productError: null
                });
                setRequiredProduct(resources.selection);
            } else if (selectedItem === 'offer-product') {
                const ids = [];
                idFromResources.forEach(item => {
                    ids.push({
                        id: item
                    })
                })
                setOfferProductIds(ids);
                setInputErrors({
                    ...inputErrors,
                    offerProductError: null
                });
                setOfferProducts(resources.selection);
            } else if (selectedItem === 'required-collection') {
                setRequiredCollectionId([{
                    id: idFromResources[0]
                }]);
                setRequiredCollection(resources.selection[0]);
                setInputErrors({
                    ...inputErrors,
                    collectionError: null
                })
            }
        } else {
            if (selectedItem === 'required-product') {
                setRequiredProductId(null);
                setRequiredProduct(null);
                setInputErrors({
                    ...inputErrors,
                    productError: null
                });
            } else if (selectedItem === 'offer-product') {
                setOfferProductIds([]);
                setOfferProducts([]);
                setInputErrors({
                    ...inputErrors,
                    offerProductError: null
                });
            } else if (selectedItem === 'required-collection') {
                setRequiredCollectionId(null);
                setRequiredCollection(null);
                setInputErrors({
                    ...inputErrors,
                    collectionError: null
                })
            }
        }

        setOpenPicker(false);
    };

    const handleSelectedItemPicker = (name) => {
        setSelectedItem(name);
        setOpenPicker(true);
    };

    const getSelectedIds = () => {
        if (selectedItem === 'required-product') {
            return requiredProductId ? requiredProductId : [];
        } else if (selectedItem === 'offer-product') {
            return offerProductIds ? offerProductIds : [];
        } else if (selectedItem === 'required-collection') {
            return requiredCollectionId ? requiredCollectionId : [];
        }
    };

    const handleValidation = (data) => {
        let errors = {};
        if (data.offerName.trim() === "") {
            errors.offerNameError = "This is required field";
        }
        if (Number(data.cartMax) < 0 && data.offerType === 'cart-limit') {
            errors.cartMaxError = "This value can not be less than 0";
        }
        if (Number(data.cartMin) < 0 && data.offerType === 'cart-limit') {
            errors.cartMinError = "This value can not be less than 0";
        }
        if (data.cartMin === "" && data.offerType === 'cart-limit') {
            errors.cartMinError = "This is required field";
        }
        if (data.cartMax === "" && data.offerType === 'cart-limit') {
            errors.cartMaxError = "This is required field";
        } else if (Number(data.cartMax) <= Number(data.cartMin) && data.offerType === 'cart-limit') {
            errors.cartMaxError = "This value can not be equal or less than cart min value";
        }
        if (Number(data.imageWidthMobile) < 0) {
            errors.imageWidthMobileError = "This value can not be less than 0";
        }
        if (Number(data.imageHeightMobile) < 0) {
            errors.imageHeightMobileError = "This value can not be less than 0";
        }
        if (Number(data.imageHeightDesktop) < 0) {
            errors.imageHeightDesktopError = "This value can not be less than 0";
        }
        if (Number(data.imageWidthDesktop) < 0) {
            errors.imageWidthDesktopError = "This value can not be less than 0";
        }
        if (!requiredProduct && data.offerType === 'specific-product') {
            errors.productError = "This is required field";
        }
        if (!requiredCollection && data.offerType === 'collection-only') {
            errors.collectionError = "This is required field";
        }
        if (!offerProducts.length) {
            errors.offerProductError = "This is required field";
        }
        if (data.offerType === 'tag' && data.productTag.trim() === '') {
            errors.productTagError = "This is required field";
        }
        if (data.recommendationText === 'other' && (!data.recommendOtherName || data.recommendOtherName.trim() === '')) {
            errors.recommendOtherNameError = "This is required field";
        }
        if (data.buttonText === 'other' && (!data.buttonOtherName || data.buttonOtherName.trim() === '')) {
            errors.buttonOtherNameError = "This is required field";
        }
        if (data.buttonMobileText === 'other' && (!data.buttonMobileOtherName || data.buttonMobileOtherName.trim() === '')) {
            errors.buttonMobileOtherNameError = "This is required field";
        }
        setInputErrors(errors);
        return errors;
    };

    const showInputError = () => {
        return (
            <div id="PolarisTextField1Error" className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                        <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                            <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" fillRule="evenodd">
                            </path>
                        </svg>
                    </span>
                </div>This is required field
            </div>
        );
    };

    const handleSubmit = () => {
        let checkValidation = handleValidation(fields);
        if (Object.keys(checkValidation).length === 0) {
            setLoading(true);
            let payload = getPayload(fields);
            if (fields.offerType === 'cart-limit') {
                payload.cartMin_meta = fields.cartMin;
                payload.cartMax_meta = fields.cartMax;
            } else if (fields.offerType === 'specific-product') {
                payload.product_meta = requiredProduct;
            } else if (fields.offerType === 'collection-only') {
                payload.collection_meta = requiredCollection;
            }
            payload.offerProducts_meta = offerProducts;
            payload.shop = shop;
            payload.recommendationText_meta = fields.recommendationText === 'other' ? fields.recommendOtherName : payload.recommendationText_meta;
            payload.buttonText_meta = fields.buttonText === 'other' ? fields.buttonOtherName : payload.buttonText_meta;
            payload.buttonMobileText_meta = fields.buttonMobileText === 'other' ? fields.buttonMobileOtherName : fields.buttonMobileText;
            const url = id ? `recommendation/${id}` : `recommendation/create`;
            API.post(url, payload).then(response => {
                setLoading(false);
                setTooltip({
                    content: `Offer ${id ? 'Updated' : 'Created'} Successfully`,
                    active: true,
                    error: false
                });
                redirectPage('recommendations');
            }).catch(err => {
                setLoading(false);
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        } else {
            setTooltip({
                content: "Please fill required fields",
                active: true,
                error: true
            });
        }
    };

    const deleteOffer = () => {
        setDeleteLoading(true);
        API.delete(`recommendation/${id}`).then(response => {
            setDeleteLoading(false);
            setTooltip({
                content: "Offer Deleted Successfully",
                active: true,
                error: false
            });
            redirectPage('recommendations');
        }).catch(err => {
            setDeleteLoading(false);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const copyToClipboard = () => {
        var textField = document.createElement('textarea')
        textField.innerText = htmlCode;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy');
        textField.remove();
        setButtonText('Copied');
    };

    const handleTabIndex = (index) => {
        setTabIndex(index);
    };

    return (
        <div className="edit-coupon-ofr">
            <TabHeader />
            <Page
                title={title}
                primaryAction={<BackButton disable={disableBackButton} url="recommendations" />}
            >
                <RecommendationPreview fields={fields} products={offerProducts} />
                <Form noValidate onSubmit={handleSubmit}>
                    <TabSettings handleTabIndex={handleTabIndex}>
                        {tabIndex === 0 && 
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        label="Offer Name"
                                        value={fields.offerName}
                                        onChange={e => handleInputChange(e, 'offerName')}
                                        error={inputErrors.offerNameError}
                                    />
                                    <Select
                                        label="Offer Type"
                                        options={[
                                            { label: 'Cart Limit', value: 'cart-limit' },
                                            { label: 'Specific Product', value: 'specific-product' },
                                            { label: 'Specific Product Tag', value: 'tag' },
                                        ]}
                                        onChange={e => handleInputChange(e, 'offerType')}
                                        value={fields.offerType}
                                        disabled={id ? true : false}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <div className="group-input-handle">
                                        <FormLayout.Group condensed>
                                            <Select
                                                label="Recommendation Text"
                                                value={fields.recommendationText}
                                                options={recommendOptions}
                                                onChange={e => handleInputChange(e, 'recommendationText')}
                                            />
                                            {fields.recommendationText === 'other' &&
                                                <TextField
                                                    label="Other"
                                                    value={fields.recommendOtherName}
                                                    maxLength="25"
                                                    onChange={e => handleInputChange(e, 'recommendOtherName')}
                                                    error={inputErrors.recommendOtherNameError}
                                                />
                                            }
                                        </FormLayout.Group>
                                    </div>
                                    {fields.offerType === 'tag' &&
                                        <div className="group-input-handle">
                                            <FormLayout.Group condensed>
                                                <TextField
                                                    label="Product Tag"
                                                    value={fields.productTag}
                                                    onChange={e => handleInputChange(e, 'productTag')}
                                                    error={inputErrors.productTagError}
                                                    helpText="Only one tag is allowed here and Tag name should be case-sensitive"
                                                />
                                            </FormLayout.Group>
                                        </div>
                                    }
                                    {fields.offerType === 'cart-limit' &&
                                        <div className="group-input-handle">
                                            <FormLayout.Group condensed>
                                                <TextField
                                                    label="Cart Min Value:"
                                                    value={fields.cartMin}
                                                    type="number"
                                                    min={0}
                                                    onChange={e => handleInputChange(e, 'cartMin')}
                                                    error={inputErrors.cartMinError}
                                                />
                                                <TextField
                                                    type="number"
                                                    value={fields.cartMax}
                                                    label="Cart Max Value:"
                                                    min={0}
                                                    onChange={(e) => handleInputChange(e, 'cartMax')}
                                                    error={inputErrors.cartMaxError}
                                                />
                                            </FormLayout.Group>
                                        </div>
                                    }
                                </FormLayout.Group>
                                {fields.offerType === 'collection-only' &&
                                    <FormLayout.Group>
                                        <Card title="Required Collection">
                                            <ResourceList
                                                resourceName={{ singular: 'sale', plural: 'pname' }}
                                                items={[
                                                    {
                                                        title: requiredCollection?.title,
                                                        image: requiredCollection?.image?.originalSrc
                                                    }
                                                ]}
                                                renderItem={(item) => {
                                                    const { title, image } = item;
                                                    const media = <Thumbnail
                                                        source={image ? image : dummyImage}
                                                        size="large"
                                                        alt={title}
                                                    />;
                                                    return (
                                                        <div className="myprdctlist">
                                                            { requiredCollection &&
                                                                <ResourceList.Item
                                                                    media={media}
                                                                    accessibilityLabel={`View pname for ${title}`}
                                                                >
                                                                    <div className="pdesc">
                                                                        <Stack.Item fill>{title}</Stack.Item>
                                                                    </div>
                                                                </ResourceList.Item>
                                                            }
                                                            <Button primary onClick={e => handleSelectedItemPicker('required-collection')}>Select Collection</Button>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            {inputErrors.collectionError &&
                                                showInputError()
                                            }
                                        </Card>
                                    </FormLayout.Group>
                                }
                                {fields.offerType === 'specific-product' &&
                                    <FormLayout.Group>
                                        <div className="recommendation-offer-products">
                                            <Card title="Required Product">
                                                <ResourceList
                                                    resourceName={{ singular: 'sale', plural: 'pname' }}
                                                    items={requiredProduct}
                                                    renderItem={(item) => {
                                                        const { title, images, variants } = item;
                                                        const image = images[0]?.originalSrc;
                                                        const media = <Thumbnail
                                                            source={image ? image : dummyImage}
                                                            size="large"
                                                            alt={title}
                                                        />;
                                                        return (
                                                            <div className="myprdctlist">
                                                                { requiredProduct &&
                                                                    <ResourceList.Item
                                                                        media={media}
                                                                        accessibilityLabel={`View pname for ${title}`}
                                                                    >
                                                                        <Stack>
                                                                            <div className="pdesc">
                                                                                <Stack.Item fill>{title}</Stack.Item>
                                                                                <Stack.Item fill>{variants[0]?.title}</Stack.Item>
                                                                                <Stack.Item>{variants[0]?.sku}</Stack.Item>
                                                                            </div>
                                                                            <div className="ptamount">
                                                                                {variants[0]?.price && <Stack.Item>${variants[0]?.price}</Stack.Item>}
                                                                            </div>
                                                                        </Stack>
                                                                    </ResourceList.Item>
                                                                }
                                                            </div>
                                                        );
                                                    }}
                                                />
                                                <Button primary onClick={e => handleSelectedItemPicker('required-product')}>Select Product</Button>
                                                {inputErrors.productError &&
                                                    showInputError()
                                                }
                                            </Card>
                                        </div>
                                    </FormLayout.Group>
                                }
                                <FormLayout.Group>
                                    <div className="recommendation-offer-products">
                                        <Card title="Offer Product" >
                                            <ResourceList
                                                resourceName={{ singular: 'sale', plural: 'pname' }}
                                                items={offerProducts}
                                                renderItem={(item) => {
                                                    const { title, images, variants } = item;
                                                    const image = images[0]?.originalSrc;
                                                    const media = <Thumbnail
                                                        source={image ? image : dummyImage}
                                                        size="large"
                                                        alt={title}
                                                    />;
                                                    return (
                                                        <div className="myprdctlist">
                                                            { offerProducts &&
                                                                <ResourceList.Item
                                                                    media={media}
                                                                    accessibilityLabel={`View pname for ${title}`}
                                                                >
                                                                    <Stack>
                                                                        <div className="pdesc">
                                                                            <Stack.Item fill>{title}</Stack.Item>
                                                                            <Stack.Item fill>{variants[0]?.title}</Stack.Item>
                                                                            <Stack.Item>{variants[0]?.sku}</Stack.Item>
                                                                        </div>
                                                                        <div className="ptamount">
                                                                            {variants[0]?.price && <Stack.Item>${variants[0]?.price}</Stack.Item>}
                                                                        </div>
                                                                    </Stack>
                                                                </ResourceList.Item>
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Button primary onClick={e => handleSelectedItemPicker('offer-product')}>Select Products</Button>
                                            {inputErrors.offerProductError &&
                                                showInputError()
                                            }
                                        </Card>
                                    </div>
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        label="Description"
                                        value={fields.description}
                                        onChange={e => handleInputChange(e, 'description')}
                                        multiline={2}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Recommendation Location"
                                        value={fields.recommendationLocation}
                                        options={recommendationLocations}
                                        onChange={e => handleInputChange(e, 'recommendationLocation')}
                                    />
                                    <Select
                                        label="Recommendation Position"
                                        value={fields.recommendationPosition}
                                        options={recommendationPosition}
                                        onChange={e => handleInputChange(e, 'recommendationPosition')}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Checkbox
                                        label="Add Sale Badge"
                                        checked={fields.saleBadge}
                                        onChange={e => handleInputChange(e, 'saleBadge')}
                                    />
                                </FormLayout.Group>
                                {fields.recommendationPosition === 'custom-position' &&
                                    <FormLayout.Group>
                                        <Banner
                                            title="Custom placement"
                                            action={{ content: copyButtonText, onClick: copyToClipboard }}
                                            status="info"
                                        >
                                            <p>Copy the html code and place it where you want to show the offer on cart page. <TextStyle variation="code"> {htmlCode} </TextStyle></p>
                                        </Banner>
                                    </FormLayout.Group>
                                }
                            </FormLayout>
                        }
                        {tabIndex === 1 && 
                            <FormLayout>
                                <Subheading>Recommendation Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Recommendation Text Color"
                                        value={colorWithoutHash(fields.recommendationTextColor)}
                                        suffix={<ColorChoser
                                            field="recommendationTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.recommendationTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="recommend"
                                        label="Recommendation Font Family"
                                        activeFont={fields.recommendationFontFamily}
                                        field="recommendationFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Recommendation Font Size"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.recommendationFontSize}
                                        onChange={e => handleInputChange(e, 'recommendationFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Description Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Description Text Color"
                                        value={colorWithoutHash(fields.descriptionTextColor)}
                                        suffix={<ColorChoser
                                            field="descriptionTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.descriptionTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="description"
                                        label="Description Font Family"
                                        activeFont={fields.descriptionFontFamily}
                                        field="descriptionFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Description Font Size"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.descriptionFontSize}
                                        onChange={e => handleInputChange(e, 'descriptionFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Product Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Product Title Text Color"
                                        value={colorWithoutHash(fields.productTitleTextColor)}
                                        suffix={<ColorChoser
                                            field="productTitleTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.productTitleTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="producttitle"
                                        label="Product Title Font Family"
                                        activeFont={fields.productTitleFontFamily}
                                        field="productTitleFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Product Title Font Size"
                                        min={10}
                                        max={20}
                                        step={1}
                                        value={fields.productTitleFontSize}
                                        onChange={e => handleInputChange(e, 'productTitleFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>20px</p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Product Price Text Color"
                                        value={colorWithoutHash(fields.productPriceTextColor)}
                                        suffix={<ColorChoser
                                            field="productPriceTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.productPriceTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="productprice"
                                        label="Product price Font Family"
                                        activeFont={fields.productPriceFontFamily}
                                        field="productPriceFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Product Price Font Size"
                                        min={10}
                                        max={20}
                                        step={1}
                                        value={fields.productPriceFontSize}
                                        onChange={e => handleInputChange(e, 'productPriceFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>20px</p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Product Image Size"
                                        value={fields.imageSizeDesktop}
                                        options={buttonSizeOptions}
                                        onChange={e => handleInputChange(e, 'imageSizeDesktop')}
                                    />
                                    <RangeSlider
                                        output
                                        label="Product Spacing"
                                        min={0}
                                        max={30}
                                        step={1}
                                        value={fields.productSpacing}
                                        onChange={e => handleInputChange(e, 'productSpacing')}
                                        prefix={<p>0</p>}
                                        suffix={<p>30</p>}
                                    />
                                    <></>
                                </FormLayout.Group>
                                <Subheading>Button Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Button Text Color"
                                        value={colorWithoutHash(fields.buttonTextColor)}
                                        suffix={<ColorChoser
                                            field="buttonTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.buttonTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="Button Background Color"
                                        onChange={e => handleColorChange(e, 'buttonBgColor')}
                                        value={colorWithoutHash(fields.buttonBgColor)}
                                        suffix={<ColorChoser
                                            field="buttonBgColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.buttonBgColor}
                                        />
                                        }
                                    />
                                    <RangeSlider
                                        output
                                        label="Button Font Size"
                                        min={10}
                                        max={20}
                                        step={1}
                                        value={fields.buttonFontSize}
                                        onChange={e => handleInputChange(e, 'buttonFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>20px</p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <FontFamilyPicker
                                        pickerId="button"
                                        label="Button Font Family"
                                        activeFont={fields.buttonFontFamily}
                                        field="buttonFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <Select
                                        label="Button Size"
                                        value={fields.buttonSizeDesktop}
                                        options={buttonSizeOptions}
                                        onChange={e => handleInputChange(e, 'buttonSizeDesktop')}
                                    />
                                    <Select
                                        label="Button Text"
                                        value={fields.buttonText}
                                        options={buttonOptions}
                                        onChange={e => handleInputChange(e, 'buttonText')}
                                    />
                                </FormLayout.Group>
                                {fields.buttonText === 'other' &&
                                    <FormLayout.Group>
                                        <TextField
                                            label="Button Other Name"
                                            value={fields.buttonOtherName}
                                            maxLength="25"
                                            onChange={e => handleInputChange(e, 'buttonOtherName')}
                                            error={inputErrors.buttonOtherNameError}
                                        />
                                        <></>
                                        <></>
                                    </FormLayout.Group>
                                }
                            </FormLayout>
                        }
                        {tabIndex === 2 && 
                            <FormLayout>
                                <Subheading>Recommendation Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Recommendation Text Color"
                                        value={colorWithoutHash(fields.recommendationMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="recommendationMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.recommendationMobileTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="recommendMobile"
                                        label="Recommendation Font Family"
                                        activeFont={fields.recommendationMobileFontFamily}
                                        field="recommendationMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Recommendation Font Size"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.recommendationMobileFontSize}
                                        onChange={e => handleInputChange(e, 'recommendationMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Description Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Description Text Color"
                                        value={colorWithoutHash(fields.descriptionMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="descriptionMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.descriptionMobileTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="descriptionMobile"
                                        label="Description Font Family"
                                        activeFont={fields.descriptionMobileFontFamily}
                                        field="descriptionMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Description Font Size"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.descriptionMobileFontSize}
                                        onChange={e => handleInputChange(e, 'descriptionMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Product Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Product Title Text Color"
                                        value={colorWithoutHash(fields.productTitleMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="productTitleMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.productTitleMobileTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="productTitleMobile"
                                        label="Product Title Font Family"
                                        activeFont={fields.productTitleMobileFontFamily}
                                        field="productTitleMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Product Title Font Size"
                                        min={10}
                                        max={20}
                                        step={1}
                                        value={fields.productTitleMobileFontSize}
                                        onChange={e => handleInputChange(e, 'productTitleMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>20px</p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Product Price Text Color"
                                        value={colorWithoutHash(fields.productPriceMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="productPriceMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.productPriceMobileTextColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="productPriceMobile"
                                        label="Product price Font Family"
                                        activeFont={fields.productPriceMobileFontFamily}
                                        field="productPriceMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Product Price Font Size"
                                        min={10}
                                        max={20}
                                        step={1}
                                        value={fields.productPriceMobileFontSize}
                                        onChange={e => handleInputChange(e, 'productPriceMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>20px</p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Product Image Size"
                                        value={fields.imageSizeMobile}
                                        options={buttonSizeOptions}
                                        onChange={e => handleInputChange(e, 'imageSizeMobile')}
                                    />
                                    <RangeSlider
                                        output
                                        label="Product Spacing"
                                        min={0}
                                        max={30}
                                        step={1}
                                        value={fields.productMobileSpacing}
                                        onChange={e => handleInputChange(e, 'productMobileSpacing')}
                                        prefix={<p>0</p>}
                                        suffix={<p>30</p>}
                                    />
                                    <></>
                                </FormLayout.Group>
                                <Subheading>Button Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Button Text Color"
                                        value={colorWithoutHash(fields.buttonMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="buttonMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.buttonMobileTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="Button Background Color"
                                        onChange={e => handleColorChange(e, 'buttonMobileBgColor')}
                                        value={colorWithoutHash(fields.buttonMobileBgColor)}
                                        suffix={<ColorChoser
                                            field="buttonMobileBgColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.buttonMobileBgColor}
                                        />
                                        }
                                    />
                                    <RangeSlider
                                        output
                                        label="Button Font Size"
                                        min={10}
                                        max={20}
                                        step={1}
                                        value={fields.buttonMobileFontSize}
                                        onChange={e => handleInputChange(e, 'buttonMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>20px</p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <FontFamilyPicker
                                        pickerId="buttonMobile"
                                        label="Button Font Family"
                                        activeFont={fields.buttonMobileFontFamily}
                                        field="buttonMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <Select
                                        label="Button Size (Mobile)"
                                        value={fields.buttonSizeMobile}
                                        options={buttonSizeOptions}
                                        onChange={e => handleInputChange(e, 'buttonSizeMobile')}
                                    />
                                    <Select
                                        label="Button Text"
                                        value={fields.buttonMobileText}
                                        options={buttonOptions}
                                        onChange={e => handleInputChange(e, 'buttonMobileText')}
                                    />
                                </FormLayout.Group>
                                {fields.buttonMobileText === 'other' &&
                                    <FormLayout.Group>
                                        <TextField
                                            label="Button Other Name"
                                            value={fields.buttonMobileOtherName}
                                            maxLength="25"
                                            onChange={e => handleInputChange(e, 'buttonMobileOtherName')}
                                            error={inputErrors.buttonMobileOtherNameError}
                                        />
                                        <></>
                                        <></>
                                    </FormLayout.Group>
                                }
                            </FormLayout>
                        }
                        {tabIndex === 3 && 
                            <FormLayout>
                                <FormLayout.Group>
                                    <Checkbox
                                        label="Enable Custom CSS"
                                        checked={fields.enableCustomCss}
                                        onChange={e => handleInputChange(e, 'enableCustomCss')}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        multiline={8}
                                        disabled={fields.enableCustomCss ? false : true}
                                        placeholder="Enter Custom CSS Here..."
                                        value={fields.customCss}
                                        onChange={e => handleInputChange(e, 'customCss')}
                                        error={inputErrors.customCss}
                                    />
                                </FormLayout.Group>
                                <CssHelper />
                            </FormLayout>
                        }
                    </TabSettings>
                    <div className="button_wrap m-t-30">
                        <Button primary submit loading={isLoading}>Save</Button>
                        {id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
                    </div>
                </Form>
                <Tooltip tooltip={toolTip} />
                {shop &&
                    <>
                        {selectedItem === 'required-collection' ? (
                            <ResourcePicker
                                resourceType="Collection"
                                open={openPicker}
                                allowMultiple={false}
                                onSelection={(resources) => handleItemSelection(resources)}
                                onCancel={() => setOpenPicker(false)}
                                initialSelectionIds={getSelectedIds()}
                            />
                        ) : (
                                <ItemPicker
                                    resourceType="Product"
                                    openPicker={openPicker}
                                    // allowMultiple={selectedItem === 'required-product' ? false : 8}
                                    allowMultiple={8}
                                    handleItemSelection={handleItemSelection}
                                    setOpenPicker={setOpenPicker}
                                    selectedItem={getSelectedIds()}
                                />
                            )}
                    </>
                }
                {/* Just added all fonts here so that all fonts can be loaded for preview */}
                <div style={{ display: 'none' }}>
                    <FontFamilyPicker
                        pickerId="recommend"
                        label="Recommendation Font Family"
                        activeFont={fields.recommendationFontFamily}
                        field="recommendationFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="description"
                        label="Description Font Family"
                        activeFont={fields.descriptionFontFamily}
                        field="descriptionFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="producttitle"
                        label="Product Title Font Family"
                        activeFont={fields.productTitleFontFamily}
                        field="productTitleFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="productprice"
                        label="Product price Font Family"
                        activeFont={fields.productPriceFontFamily}
                        field="productPriceFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="button"
                        label="Button Font Family"
                        activeFont={fields.buttonFontFamily}
                        field="buttonFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="recommendMobile"
                        label="Recommendation Font Family"
                        activeFont={fields.recommendationMobileFontFamily}
                        field="recommendationMobileFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="descriptionMobile"
                        label="Description Font Family"
                        activeFont={fields.descriptionMobileFontFamily}
                        field="descriptionMobileFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="productTitleMobile"
                        label="Product Title Font Family"
                        activeFont={fields.productTitleMobileFontFamily}
                        field="productTitleMobileFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="productPriceMobile"
                        label="Product price Font Family"
                        activeFont={fields.productPriceMobileFontFamily}
                        field="productPriceMobileFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="buttonMobile"
                        label="Button Font Family"
                        activeFont={fields.buttonMobileFontFamily}
                        field="buttonMobileFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                </div>
            </Page>
        </div>
    );
}

export default Recommendation;