import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style.scss';
import * as serviceWorker from './serviceWorker';
import { AppProvider, Page } from "@shopify/polaris";
import { Provider } from '@shopify/app-bridge-react';
import en from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/dist/styles.css';
import MainApp from './App';
// import CookiePage from './component/Cookie';
import GlobalPlanContextProvider from './context/GlobalContext';
import { getStoreName } from './common/Helper';

function Index() {
    const config = { apiKey: 'f0ac34c864e5a626de695650ccba23ed', shopOrigin: getStoreName() };
    return (
        <GlobalPlanContextProvider>
            <Provider config={config}>
                <AppProvider i18n={en}>
                    <Page>
                        <MainApp />
                    </Page>
                </AppProvider>
            </Provider>
        </GlobalPlanContextProvider>
    );
    /* try {
        const shop = localStorage.getItem('storeName');
        if (shop) {
            const config = { apiKey: 'a61a22a306f66310863e416c7c2e7bea', shopOrigin: shop };
            // const config = { apiKey: 'f0ac34c864e5a626de695650ccba23ed', shopOrigin: shop };
            return (
                <GlobalPlanContextProvider>
                    <Provider config={config}>
                        <AppProvider i18n={en}>
                            <Page>
                                <MainApp />
                            </Page>
                        </AppProvider>
                    </Provider>
                </GlobalPlanContextProvider>
            );
        }
        return (
            <GlobalPlanContextProvider>
                <AppProvider i18n={en}>
                    <Page>
                        <MainApp />
                    </Page>
                </AppProvider>
            </GlobalPlanContextProvider>
        )
    } catch (e) {
        return (
            <AppProvider i18n={en}>
                <Page>
                    <CookiePage />
                </Page>
            </AppProvider>
        );
    } */
};

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
