import React, { useState, useEffect } from 'react';
import { RangeSlider, Select, TextField, Form, FormLayout, Button, Page, Checkbox, Icon } from '@shopify/polaris';
import ColorChoser from '../../common/Color';
import TabHeader from '../../common/TabHeader';
import { colorWithoutHash, textAlignmentOptions, getPayload, redirectPage, makingFields, getStoreName } from '../../common/Helper';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import { useParams } from "react-router-dom";
// import ProgressBar from '../../common/ProgressBar';
import ProgressBar from './preview';
import BackButton from '../../common/BackButton';
import FontFamilyPicker from '../../common/FontPicker';
import TabSettings from '../../common/TabSettings';
import {
    ExternalMinor
} from '@shopify/polaris-icons';

let initialStates = {
    status: 0,
    discountCode: '',
    name: '',
    enableBoostBar: false,
    boostBarBgColor: '#000000',
    boostBarFontSize: 15,
    boostBarFontFamily: '',
    boostBarTextAlign: 'center',
    boostBarSiteWideMessage: 'Get FREE Coupon',
    boostBarSiteWideMessage1: '',
    boostBarSiteWideMessage2: '',
    boostBarSiteWideMessage3: '',
    boostBarMessageColor: '#ffffff',
    boostBarMessageColor1: '#ffffff',
    boostBarMessageColor2: '#ffffff',
    boostBarMessageColor3: '#ffffff',
    enableCartBar: false,
    boostBarMessagesType: 'single',
    scrollSpeed: 'medium',
    boostBarSiteWideLink: '',
    boostBarSiteWideLink1: '',
    boostBarSiteWideLink2: '',
    boostBarSiteWideLink3: '',
    boostBarMobileTextAlign: 'center',
    boostBarMobileFontSize: 15,
    boostBarMobileFontFamily: '',
    boostBarSiteWideMobileMessage: "Get FREE Coupon",
    boostBarSiteWideMobileMessage1: '',
    boostBarSiteWideMobileMessage2: '',
    boostBarSiteWideMobileMessage3: '',
    boostBarMobileMessageColor: '#ffffff',
    boostBarMobileMessageColor1: '#ffffff',
    boostBarMobileMessageColor2: '#ffffff',
    boostBarMobileMessageColor3: '#ffffff',
    boostBarSiteWideMobileLink: '',
    boostBarSiteWideMobileLink1: '',
    boostBarSiteWideMobileLink2: '',
    boostBarSiteWideMobileLink3: '',

};

export default function CouponCartBar() {
    const shop = getStoreName();
    const { id } = useParams();

    const [title, setTitle] = useState("BoostBar Settings");
    const [fields, setFields] = useState(initialStates);
    const [disableBackButton, setDisableBackButton] = useState(false);

    const [inputErrors, setInputErrors] = useState({
        nameError: null,
        mobileMessageError: null,
        desktopMessageError: null
    });

    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });
    const [tabIndex, setTabIndex] = useState(0);
    /**get data from db */
    useEffect(() => {
        if (id) {
            API.get(`boost-bar/${id}`).then(response => {
                const boostBar = response.data.data.boostBar;
                const metaData = JSON.parse(boostBar.boostbarOptions);
                setTitle(boostBar.boostbarName);
                let dbFields = makingFields(metaData);
                dbFields.name = boostBar.boostbarName;
                dbFields.status = boostBar.boostbarStatus;
                dbFields.scrollSpeed = dbFields.scrollSpeed ?? 'medium';
                //make initialStates with db values
                initialStates = dbFields;
                setFields(dbFields);
            }).catch(err => {
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        }
    }, [id, shop]);

    /** Disable back button if form is edited */
    useEffect(() => {
        if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
            setDisableBackButton(false);
        } else {
            setDisableBackButton(true);
        }
    }, [fields]);

    const handleInputChange = (inputValue, inputName) => {
        setFields({
            ...fields,
            [inputName]: inputValue
        })
    };

    const handleColorChange = (color, inputName) => {
        const value = color.search('#') >= 0 ? color : '#' + color;
        setFields({
            ...fields,
            [inputName]: value
        });
    };

    const handleValidation = (data) => {
        let errors = {};
        if (data.name.trim() === "") {
            errors.nameError = "This is required field";
        }
        if (data.boostBarSiteWideMobileMessage.trim() === "") {
            errors.mobileMessageError = "This is required field";
        }
        if (data.boostBarSiteWideMessage.trim() === "") {
            errors.desktopMessageError = "This is required field";
        }
        setInputErrors(errors);
        return errors;
    };

    const handleSubmit = () => {
        let checkValidation = handleValidation(fields);
        if (Object.keys(checkValidation).length === 0) {
            setLoading(false);
            let payload = getPayload(fields);
            payload.shop = shop;
            const url = id ? `boost-bar/${id}` : `boostbar/create`;
            API.post(url, payload).then(response => {
                setLoading(false);
                setTooltip({
                    content: `Offer ${id ? 'Updated' : 'Created'} Successfully`,
                    active: true,
                    error: false
                });
                redirectPage('boost-bar');
            }).catch(err => {
                setLoading(false);
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        } else {
            setTooltip({
                content: "Please fill required fields",
                active: true,
                error: true
            });
        }
    };

    const deleteOffer = () => {
        setDeleteLoading(true);
        API.delete(`boost-bar/${id}`).then(response => {
            setDeleteLoading(false);
            setTooltip({
                content: "Offer Deleted Successfully",
                active: true,
                error: false
            });
            redirectPage('boost-bar');
        }).catch(err => {
            setDeleteLoading(false);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const handleTabIndex = (index) => {
        setTabIndex(index);
    };

    return (
        <div className='edit-coupon-ofr'>
            <TabHeader />
            <Page
                title={title}
                primaryAction={<BackButton disable={disableBackButton} url="boost-bar" />}
            >
                <ProgressBar title="BoostBar Preview" fields={fields} handleBarInput={handleInputChange} />
                <Form noValidate onSubmit={handleSubmit}>
                    <TabSettings handleTabIndex={handleTabIndex} excludeIndex={3}>
                        {tabIndex === 0 &&
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        label="Title"
                                        value={fields.name}
                                        onChange={e => handleInputChange(e, 'name')}
                                        error={inputErrors.nameError}
                                    />
                                    <TextField
                                        prefix="#"
                                        label="BoostBar Background Color"
                                        onChange={e => handleColorChange(e, 'boostBarBgColor')}
                                        value={colorWithoutHash(fields.boostBarBgColor)}
                                        suffix={<ColorChoser
                                            field="boostBarBgColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.boostBarBgColor}
                                        />
                                        }
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Number of BoostBar Sitewide Messages"
                                        options={[
                                            { label: 'Single', value: 'single' },
                                            { label: 'Mulitple', value: 'multiple' },
                                        ]}
                                        onChange={e => handleInputChange(e, 'boostBarMessagesType')}
                                        value={fields.boostBarMessagesType}
                                    />
                                    <div style={{ marginTop: '20px' }}>
                                        <Checkbox
                                            label="Enable CartBar Message(CartBar must be active)"
                                            checked={fields.enableCartBar}
                                            onChange={e => handleInputChange(e, 'enableCartBar')}
                                        />
                                    </div>
                                </FormLayout.Group>
                                {fields.boostBarMessagesType === 'multiple' &&
                                    <FormLayout.Group>
                                        <Select
                                            label="Scroll Speed"
                                            options={[
                                                { label: 'Slow', value: 'slow' },
                                                { label: 'Medium', value: 'medium' },
                                                { label: 'Fast', value: 'fast' },
                                            ]}
                                            onChange={e => handleInputChange(e, 'scrollSpeed')}
                                            value={fields.scrollSpeed}
                                        />
                                        <></>
                                    </FormLayout.Group>
                                }
                            </FormLayout>
                        }
                        {tabIndex === 1 &&
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        label="BoostBar Sitewide Text"
                                        value={fields.boostBarSiteWideMessage}
                                        onChange={e => handleInputChange(e, 'boostBarSiteWideMessage')}
                                        error={inputErrors.desktopMessageError}
                                        maxLength={90}
                                    />
                                    <TextField
                                        prefix="#"
                                        label="BoostBar Text Color"
                                        onChange={e => handleColorChange(e, 'boostBarMessageColor')}
                                        value={colorWithoutHash(fields.boostBarMessageColor)}
                                        suffix={<ColorChoser
                                            field="boostBarMessageColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.boostBarMessageColor}
                                        />
                                        }
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        label="BoostBar Sitewide Link  (optional)"
                                        value={fields.boostBarSiteWideLink}
                                        onChange={e => handleInputChange(e, 'boostBarSiteWideLink')}
                                        prefix={<Icon source={ExternalMinor} />}
                                    />
                                    <></>
                                </FormLayout.Group>
                                {fields.boostBarMessagesType === 'multiple' &&
                                    <div className="m-t-10">
                                        <FormLayout>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Text 1 (optional)"
                                                    value={fields.boostBarSiteWideMessage1}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMessage1')}
                                                    maxLength={90}
                                                />
                                                <TextField
                                                    prefix="#"
                                                    label="BoostBar Text Color 1"
                                                    onChange={e => handleColorChange(e, 'boostBarMessageColor1')}
                                                    value={colorWithoutHash(fields.boostBarMessageColor1)}
                                                    suffix={<ColorChoser
                                                        field="boostBarMessageColor1"
                                                        handleColorInput={handleInputChange}
                                                        color={fields.boostBarMessageColor1}
                                                    />
                                                    }
                                                />
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Link 1 (optional)"
                                                    value={fields.boostBarSiteWideLink1}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideLink1')}
                                                    prefix={<Icon source={ExternalMinor} />}
                                                />
                                                <></>
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Text 2 (optional)"
                                                    value={fields.boostBarSiteWideMessage2}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMessage2')}
                                                    maxLength={90}
                                                />
                                                <TextField
                                                    prefix="#"
                                                    label="BoostBar Text Color 2"
                                                    onChange={e => handleColorChange(e, 'boostBarMessageColor2')}
                                                    value={colorWithoutHash(fields.boostBarMessageColor2)}
                                                    suffix={<ColorChoser
                                                        field="boostBarMessageColor2"
                                                        handleColorInput={handleInputChange}
                                                        color={fields.boostBarMessageColor2}
                                                    />
                                                    }
                                                />
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Link 2 (optional)"
                                                    value={fields.boostBarSiteWideLink2}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideLink2')}
                                                    prefix={<Icon source={ExternalMinor} />}
                                                />
                                                <></>
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Text 3 (optional)"
                                                    value={fields.boostBarSiteWideMessage3}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMessage3')}
                                                    maxLength={90}
                                                />
                                                <TextField
                                                    prefix="#"
                                                    label="BoostBar Text Color 3"
                                                    onChange={e => handleColorChange(e, 'boostBarMessageColor3')}
                                                    value={colorWithoutHash(fields.boostBarMessageColor3)}
                                                    suffix={<ColorChoser
                                                        field="boostBarMessageColor3"
                                                        handleColorInput={handleInputChange}
                                                        color={fields.boostBarMessageColor3}
                                                    />
                                                    }
                                                />
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Link 3 (optional)"
                                                    value={fields.boostBarSiteWideLink3}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideLink3')}
                                                    prefix={<Icon source={ExternalMinor} />}
                                                />
                                                <></>
                                            </FormLayout.Group>
                                        </FormLayout>
                                    </div>
                                }
                                <FormLayout.Group>
                                    <FontFamilyPicker
                                        pickerId="pcFont"
                                        label="BoostBar Font Family"
                                        activeFont={fields.boostBarFontFamily}
                                        field="boostBarFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <Select
                                        label="BoostBar Text Alignment"
                                        options={textAlignmentOptions}
                                        onChange={e => handleInputChange(e, 'boostBarTextAlign')}
                                        value={fields.boostBarTextAlign}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <RangeSlider
                                        output
                                        label="BoostBar Font Size"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.boostBarFontSize}
                                        onChange={e => handleInputChange(e, 'boostBarFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        }
                        {tabIndex === 2 &&
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        label="BoostBar Sitewide Text"
                                        value={fields.boostBarSiteWideMobileMessage}
                                        onChange={e => handleInputChange(e, 'boostBarSiteWideMobileMessage')}
                                        error={inputErrors.mobileMessageError}
                                        maxLength={40}
                                    />
                                    <TextField
                                        prefix="#"
                                        label="BoostBar Text Color"
                                        onChange={e => handleColorChange(e, 'boostBarMobileMessageColor')}
                                        value={colorWithoutHash(fields.boostBarMobileMessageColor)}
                                        suffix={<ColorChoser
                                            field="boostBarMobileMessageColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.boostBarMobileMessageColor}
                                        />
                                        }
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        label="BoostBar Sitewide Link  (optional)"
                                        value={fields.boostBarSiteWideMobileLink}
                                        onChange={e => handleInputChange(e, 'boostBarSiteWideMobileLink')}
                                        prefix={<Icon source={ExternalMinor} />}
                                    />
                                    <></>
                                </FormLayout.Group>
                                {fields.boostBarMessagesType === 'multiple' &&
                                    <div className="m-t-10">
                                        <FormLayout>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Text 1 (optional)"
                                                    value={fields.boostBarSiteWideMobileMessage1}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMobileMessage1')}
                                                    maxLength={40}
                                                />
                                                <TextField
                                                    prefix="#"
                                                    label="BoostBar Text Color 1"
                                                    onChange={e => handleColorChange(e, 'boostBarMobileMessageColor1')}
                                                    value={colorWithoutHash(fields.boostBarMobileMessageColor1)}
                                                    suffix={<ColorChoser
                                                        field="boostBarMobileMessageColor1"
                                                        handleColorInput={handleInputChange}
                                                        color={fields.boostBarMobileMessageColor1}
                                                    />
                                                    }
                                                />
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Link 1 (optional)"
                                                    value={fields.boostBarSiteWideMobileLink1}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMobileLink1')}
                                                    prefix={<Icon source={ExternalMinor} />}
                                                />
                                                <></>
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Text 2 (optional)"
                                                    value={fields.boostBarSiteWideMobileMessage2}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMobileMessage2')}
                                                    maxLength={40}
                                                />
                                                <TextField
                                                    prefix="#"
                                                    label="BoostBar Text Color 2"
                                                    onChange={e => handleColorChange(e, 'boostBarMobileMessageColor2')}
                                                    value={colorWithoutHash(fields.boostBarMobileMessageColor2)}
                                                    suffix={<ColorChoser
                                                        field="boostBarMobileMessageColor2"
                                                        handleColorInput={handleInputChange}
                                                        color={fields.boostBarMobileMessageColor2}
                                                    />
                                                    }
                                                />
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Link 2 (optional)"
                                                    value={fields.boostBarSiteWideMobileLink2}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMobileLink2')}
                                                    prefix={<Icon source={ExternalMinor} />}
                                                />
                                                <></>
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Text 3 (optional)"
                                                    value={fields.boostBarSiteWideMobileMessage3}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMobileMessage3')}
                                                    maxLength={40}
                                                />
                                                <TextField
                                                    prefix="#"
                                                    label="BoostBar Text Color 3"
                                                    onChange={e => handleColorChange(e, 'boostBarMobileMessageColor3')}
                                                    value={colorWithoutHash(fields.boostBarMobileMessageColor3)}
                                                    suffix={<ColorChoser
                                                        field="boostBarMobileMessageColor3"
                                                        handleColorInput={handleInputChange}
                                                        color={fields.boostBarMobileMessageColor3}
                                                    />
                                                    }
                                                />
                                            </FormLayout.Group>
                                            <FormLayout.Group>
                                                <TextField
                                                    label="BoostBar Sitewide Link 3 (optional)"
                                                    value={fields.boostBarSiteWideMobileLink3}
                                                    onChange={e => handleInputChange(e, 'boostBarSiteWideMobileLink3')}
                                                    prefix={<Icon source={ExternalMinor} />}
                                                />
                                                <></>
                                            </FormLayout.Group>
                                        </FormLayout>
                                    </div>
                                }
                                <FormLayout.Group>
                                    <FontFamilyPicker
                                        pickerId="mobileFont"
                                        label="BoostBar Font Family"
                                        activeFont={fields.boostBarMobileFontFamily}
                                        field="boostBarMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <Select
                                        label="BoostBar Text Alignment"
                                        options={textAlignmentOptions}
                                        onChange={e => handleInputChange(e, 'boostBarMobileTextAlign')}
                                        value={fields.boostBarMobileTextAlign}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <RangeSlider
                                        output
                                        label="BoostBar Font Size"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.boostBarMobileFontSize}
                                        onChange={e => handleInputChange(e, 'boostBarMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        }
                    </TabSettings>
                    <div className="button_wrap m-t-30">
                        <Button primary submit loading={isLoading}>Save</Button>
                        {id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
                    </div>
                </Form>
                {/* Just added all fonts here so that all fonts can be loaded for preview */}
                <div style={{ display: 'none' }}>
                    <FontFamilyPicker
                        pickerId="pcFont"
                        label="BoostBar Font Family"
                        activeFont={fields.boostBarFontFamily}
                        field="boostBarFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                    <FontFamilyPicker
                        pickerId="mobileFont"
                        label="BoostBar Font Family"
                        activeFont={fields.boostBarMobileFontFamily}
                        field="boostBarMobileFontFamily"
                        handleFontFamilyInput={handleInputChange}
                    />
                </div>
                <Tooltip tooltip={toolTip} />
            </Page>
        </div>
    );
}

