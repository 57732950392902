import React, { useState, useEffect } from 'react';
import { Card, Page, TextField, Form, FormLayout, ChoiceList, TextStyle, Select, Subheading, Button, ResourceList, ResourceItem, Thumbnail, Icon } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import { useParams } from "react-router-dom";
import Tooltip from '../../common/Tooltip';
import ItemPicker from '../../common/ItemPicker';
import { ResourcePicker } from '@shopify/app-bridge-react';
import { dummyImage, getPayload, priceRoundingOptions, makingFields, redirectPage, getStoreName } from '../../common/Helper';
import BackButton from '../../common/BackButton';
import {
    DiscountsMajorMonotone,
    CashDollarMajorMonotone,
    CollectionsMajorMonotone,
    ProductsMajorMonotone,
    StoreMajorMonotone
} from '@shopify/polaris-icons';
import API from '../../service/Api';
import RequiredError from '../../common/RequiredError';

let initialStates = {
    name: '',
    offerType: 'percentage-discount',
    amount: '0',
    priceRounding: 'no-round',
    target: 'specific-collection',
    status: 0
};

export default function NewSale() {

    const shop = getStoreName();
    const { id } = useParams();
    const [title, setTitle] = useState("Create New Sale");

    const [requiredCollectionId, setRequiredCollectionId] = useState(null);
    const [requiredCollection, setRequiredCollection] = useState(null);
    const [offerProductIds, setOfferProductIds] = useState([]);
    const [offerProducts, setOfferProducts] = useState([]);
    const [openPicker, setOpenPicker] = useState(false);
    const [selectedItem, setSelectedItem] = useState('required-collection');
    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [disableBackButton, setDisableBackButton] = useState(false);
    const [inputErrors, setInputErrors] = useState({
        titleError: null,
        saleAmountError: null,
        badgeTitleError: null,
        collectionError: null,
        productsError: null
    });

    const [fields, setFields] = useState(initialStates);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });

    /** edit */
    useEffect(() => {
        if (id) {
            API.get(`sales-boost/fetch/${id}`).then(response => {
                const saleData = response.data.data.saleData;
                setTitle(saleData.title);
                const metaData = JSON.parse(saleData.metadata);
                let dbFields = makingFields(metaData);
                dbFields.name = saleData.title;
                dbFields.offerType = saleData.type;
                dbFields.status = saleData.status;
                //make initialStates with db values
                initialStates = dbFields;
                setFields(dbFields);
                if (metaData.targetCollection_meta && metaData.targetCollection_meta.id) {
                    setRequiredCollectionId([{
                        id: metaData.targetCollection_meta.id,
                        variants: [{
                            id: metaData.targetCollection_meta.id,
                        }],
                    }]);
                    setRequiredCollection(metaData.targetCollection_meta);
                }
                if (metaData.targetProducts_meta && metaData.targetProducts_meta.length) {
                    setOfferProducts(metaData.targetProducts_meta);
                    const ids = [];
                    metaData.targetProducts_meta.forEach(item => {
                        ids.push({
                            id: item.id
                        })
                    })
                    setOfferProductIds(ids);
                }
            }).catch(err => {
                console.log('err: ', err);
            });
        }
    }, [id, shop])

    /** Disable back button if form is edited */
    useEffect(() => {
        if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
            setDisableBackButton(false);
        } else {
            setDisableBackButton(true);
        }
    }, [fields]);

    const handleInputChange = (inputValue, inputName) => {
        if (inputName === 'target' || inputName === 'offerType') {
            inputValue = inputValue[0];
        }
        setFields({
            ...fields,
            [inputName]: inputValue
        })
    };

    const getRoundText = () => {
        if (fields.priceRounding === 'no-round') {
            return "Don't round prices at all";
        } else if (fields.priceRounding === 'nearest-round') {
            return "Round prices to closest whole numbers. (Eg: $4.50 rounds to $5.00 and $4.49 rounds to $4.00)";
        } else if (fields.priceRounding === 'nearest-10-cents') {
            return "Round to the closest 0.10 (Eg: $4.45 rounds to $4.50 and $4.43 rounds to $4.40)";
        } else if (fields.priceRounding === 'round-up') {
            return "Only round prices up. (Eg: $4.02 rounds to $5.00)";
        } else if (fields.priceRounding === 'round-down') {
            return "Only round prices down. (Eg: $4.99 rounds to $4.00)";
        }
    };

    const getSelectedIds = () => {
        if (selectedItem === 'offer-product') {
            return offerProductIds ? offerProductIds : [];
        } else if (selectedItem === 'required-collection') {
            return requiredCollectionId ? requiredCollectionId : [];
        }
    };

    const handleSelectedItemPicker = (name) => {
        setSelectedItem(name);
        setOpenPicker(true);
    };

    const handleItemSelection = (resources) => {
        const idFromResources = resources.selection.map((item) => item.id);
        if (idFromResources.length) {
            if (selectedItem === 'offer-product') {
                const ids = [];
                idFromResources.forEach(item => {
                    ids.push({
                        id: item
                    })
                })
                setOfferProductIds(ids);
                setInputErrors({
                    ...inputErrors,
                    offerProductError: null
                });
                setOfferProducts(resources.selection);
            } else if (selectedItem === 'required-collection') {
                setRequiredCollectionId([{
                    id: idFromResources[0]
                }]);
                setRequiredCollection(resources.selection[0]);
                setInputErrors({
                    ...inputErrors,
                    collectionError: null
                })
            }
            if (disableBackButton === false) {
                setDisableBackButton(true);
            }	
        } else {
            if (selectedItem === 'offer-product') {
                setOfferProductIds([]);
                setOfferProducts([]);
                setInputErrors({
                    ...inputErrors,
                    offerProductError: null
                });
            } else if (selectedItem === 'required-collection') {
                setRequiredCollectionId(null);
                setRequiredCollection(null);
                setInputErrors({
                    ...inputErrors,
                    collectionError: null
                })
            }
            if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
                setDisableBackButton(false);
            }
        }

        setOpenPicker(false);
    };


    const handleValidation = (data) => {
        let errors = {};
        if (data.name.trim() === "") {
            errors.titleError = "This is required field";
        }
        if (data.amount.trim() === "" || data.amount === null) {
            errors.saleAmountError = "This is required field";
        } else if (Number(data.amount) < 1) {
            errors.saleAmountError = "The value can not be less than 1";
        }
        if (!requiredCollection && data.target === "specific-collection") {
            errors.collectionError = "Please choose one collection"
        }
        if (data.target === "manually-products" && offerProducts.length <= 0) {
            errors.productsError = "Please choose atleast one product"
        }
        setInputErrors(errors);
        return errors;
    };

    const handleSubmit = () => {
        let checkValidation = handleValidation(fields);
        if (Object.keys(checkValidation).length === 0) { 
            setLoading(true);
            let payload = getPayload(fields);
            payload.shop = shop;
            if (fields.target === 'specific-collection') {
                payload.targetCollection_meta = requiredCollection;
            }
            if (fields.target === 'manually-products') {
                payload.targetProducts_meta = offerProducts;
            }
            const url = id ? `sales-boost/${id}` : `sales-boost/create`;
            API.post(url, payload).then(response => {
                setLoading(false);
                setTooltip({
                    content: `Offer ${id ? 'Updated' : 'Created'} Successfully`,
                    active: true,
                    error: false
                });
                redirectPage('sales');
            }).catch(err => {
                setLoading(false);
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        } else {
            setTooltip({
                content: "Please fill required fields",
                active: true,
                error: true
            });
        }
    };

    const deleteOffer = () => {
        setDeleteLoading(true);
        API.delete(`sales-boost/${id}`).then(response => {
            setDeleteLoading(false);
            setTooltip({
                content: "Offer Deleted Successfully",
                active: true,
                error: false
            });
            redirectPage('sales');
        }).catch(err => {
            setDeleteLoading(false);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    return (
        <div className="edit-coupon-ofr" id="new-sale">
            <TabHeader />
            <Page
                title={title}
                primaryAction={<BackButton disable={disableBackButton} url="sales" />}
            >
                <Form noValidate onSubmit={handleSubmit}>
                    <Card title="Sale Settings" sectioned>
                        <TextStyle variation="subdued">
                            Specify the settings of the sale. You can select whether the sale is a percentage discount or a fixed amount off.
                        </TextStyle>
                        <div className="m-t-40">
                            <FormLayout>
                                <TextField
                                    label="Sale Title"
                                    value={fields.name}
                                    onChange={e => handleInputChange(e, 'name')}
                                    error={inputErrors.titleError}
                                />
                                <FormLayout.Group condensed>
                                    <div className="custom-icon-radio">
                                        <div className="icon-outer">
                                            <Icon source={DiscountsMajorMonotone} />
                                        </div>
                                        <ChoiceList
                                            choices={[
                                                { label: 'Percentage discount', value: 'percentage-discount' },
                                            ]}
                                            selected={fields.offerType}
                                            onChange={e => handleInputChange(e, 'offerType')}
                                        />
                                    </div>
                                    <div className="custom-icon-radio">
                                        <div className="icon-outer">
                                            <Icon source={CashDollarMajorMonotone} />
                                        </div>
                                        <ChoiceList
                                            choices={[
                                                { label: 'Fixed amount off', value: 'fixed-amount' },
                                            ]}
                                            selected={fields.offerType}
                                            onChange={e => handleInputChange(e, 'offerType')}
                                        />
                                    </div>
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        label="Sale amount"
                                        min={0}
                                        type="number"
                                        value={fields.amount}
                                        onChange={e => handleInputChange(e, 'amount')}
                                        error={inputErrors.saleAmountError}
                                    />
                                    <Select
                                        label="Price Rounding"
                                        options={priceRoundingOptions}
                                        onChange={e => handleInputChange(e, 'priceRounding')}
                                        placeholder="Round the prices?"
                                        value={fields.priceRounding}
                                        helpText={getRoundText()}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </div>
                    </Card>
                    <Card title="Sale target settings" sectioned>
                        <TextStyle variation="subdued">
                            Select to apply to products in a collection or to the whole store. You can also choose to manually select which products are in the sale.
                        </TextStyle>
                        <div className="m-t-30">
                            <FormLayout>
                                <Subheading>Behaviour</Subheading>
                                <FormLayout.Group condensed>
                                    <div className="custom-icon-radio">
                                        <div className="icon-outer">
                                            <Icon source={CollectionsMajorMonotone} />
                                        </div>
                                        <ChoiceList
                                            choices={[
                                                { label: 'Apply to a collection', value: 'specific-collection' },
                                            ]}
                                            selected={fields.target}
                                            onChange={e => handleInputChange(e, 'target')}
                                        />
                                    </div>
                                    <div className="custom-icon-radio">
                                        <div className="icon-outer">
                                            <Icon source={ProductsMajorMonotone} />
                                        </div>
                                        <ChoiceList
                                            choices={[
                                                { label: 'Manually select products', value: 'manually-products' },
                                            ]}
                                            selected={fields.target}
                                            onChange={e => handleInputChange(e, 'target')}
                                        />
                                    </div>
                                    <div className="custom-icon-radio">
                                        <div className="icon-outer">
                                            <Icon source={StoreMajorMonotone} />
                                        </div>
                                        <ChoiceList
                                            choices={[
                                                { label: 'Apply to the whole store', value: 'whole-store' },
                                            ]}
                                            selected={fields.target}
                                            onChange={e => handleInputChange(e, 'target')}
                                        />
                                    </div>
                                </FormLayout.Group>
                            </FormLayout>
                            <FormLayout>
                                <div className="m-t-40">
                                    {fields.target === 'specific-collection' &&
                                        <Button onClick={e => handleSelectedItemPicker('required-collection')}>Choose collection</Button>
                                    }
                                    {fields.target === 'manually-products' &&
                                        <FormLayout.Group>
                                            <Button onClick={e => handleSelectedItemPicker('offer-product')}>Create a new private collection</Button>
                                            <TextStyle variation="negative">Note: once a private collection is created, this sale will permenantly be manually managed.</TextStyle>
                                        </FormLayout.Group>
                                    }
                                    {fields.target === 'whole-store' &&
                                        <TextStyle variation="strong">Note: all products on your shop will be updated</TextStyle>
                                    }
                                </div>
                            </FormLayout>
                            <FormLayout>
                                <div className="m-t-40">
                                    {requiredCollection && fields.target === 'specific-collection' &&
                                        <div>Current Collection: <b>{requiredCollection.title}</b></div>
                                    }
                                    {offerProducts.length > 0 && fields.target === 'manually-products' &&
                                        <ResourceList
                                            resourceName={{ singular: 'sale', plural: 'pname' }}
                                            items={offerProducts}
                                            renderItem={(item) => {
                                                const { title, images, variants } = item;
                                                const image = images[0]?.originalSrc;
                                                const media = <Thumbnail
                                                    source={image ? image : dummyImage}
                                                    size="large"
                                                    alt={title}
                                                />;
                                                return (
                                                    <ResourceItem
                                                        media={media}
                                                    >
                                                        <h3>
                                                            <TextStyle variation="strong">{title}</TextStyle>
                                                        </h3>
                                                        <div>{variants.length} { variants.length > 1 ? 'variants' : 'variant'}</div>
                                                    </ResourceItem>
                                                );
                                            }}
                                        />
                                    }
                                </div>
                            </FormLayout>
                            <div>
                                {fields.target === 'specific-collection' && inputErrors.collectionError &&
                                    <RequiredError message={inputErrors.collectionError} />
                                }
                                {fields.target === 'manually-products' && inputErrors.productsError &&
                                    <RequiredError message={inputErrors.productsError} />
                                }
                            </div>
                        </div>
                        <TextStyle variation="strong">Note: You will not be able to select which products will remove the sale on deactivation.</TextStyle>
                    </Card>
                    
                    <div className="button_wrap m-t-30">
                        <Button primary submit loading={isLoading}>Save</Button>
                        {id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
                    </div>

                    {shop &&
                        <>
                            {selectedItem === 'required-collection' ? (
                                <ResourcePicker
                                    resourceType="Collection"
                                    open={openPicker}
                                    allowMultiple={false}
                                    onSelection={(resources) => handleItemSelection(resources)}
                                    onCancel={() => setOpenPicker(false)}
                                    initialSelectionIds={getSelectedIds()}
                                />
                            ) : (
                                    <ItemPicker
                                        resourceType="Product"
                                        openPicker={openPicker}
                                        allowMultiple={selectedItem === 'required-product' ? false : true}
                                        handleItemSelection={handleItemSelection}
                                        setOpenPicker={setOpenPicker}
                                        selectedItem={getSelectedIds()}
                                    />
                                )}
                        </>
                    }
                </Form>
                <Tooltip tooltip={toolTip} />
            </Page>
        </div>
    );
}



