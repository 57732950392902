import React, { useEffect, useState } from 'react';
import { Card, Page, Badge, Stack, ButtonGroup, Button, DisplayText, Icon } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import EmptyOffer from '../EmptyOffer';
import Loader from '../../common/Loader';
import { PlusMinor } from '@shopify/polaris-icons';
import { redirectPage, getStoreName } from '../../common/Helper';

export default function TabsExample() {
    const shop = getStoreName();
    const [statusLoading, setStatusLoding] = useState(null);
    const [deleteLoding, setDeleteLoding] = useState(null);
    const [offers, setOffers] = useState([]);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });
    // get offers from db
    useEffect(() => {
        API.get(`recommendation/fetch`).then(response => {
            setOffers(response.data.data);
            setSkeletonLoader(false);
        }).catch(err => {
            setSkeletonLoader(false);
        });
    }, [shop]);

    const deleteOffer = (id) => {
        setDeleteLoding(id);
        API.delete(`recommendation/${id}`).then(response => {
            setTooltip({
                content: "Offer Deleted Successfully",
                active: true,
                error: false
            });
            const sells = [...offers];
            const newOffers = sells.filter(x => x.id !== id);
            setOffers(newOffers);
            setDeleteLoding(null);
        }).catch(err => {
            setDeleteLoding(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const changeStatus = (offerId) => {
        setStatusLoding(offerId);
        const allOffers = [...offers];
        const index = allOffers.findIndex(x => x.id === offerId);
        const url = allOffers[index].offerStatus === 0 ? 'activate' : 'deactivate';
        API.post(`recommendation/${url}/${offerId}`).then(response => {
            allOffers[index].offerStatus = allOffers[index].offerStatus === 0 ? 1 : 0;
            setOffers(allOffers);
            setTooltip({
                content: "Status Changed Successfully",
                active: true,
                error: false
            });
            setStatusLoding(null);
        }).catch(err => {
            setStatusLoding(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };


    return (
        <div className='upsells-outr'>
            <TabHeader />
            <Page
                title="Recommendation"
                primaryAction={
                    <Button primary icon={<Icon source={PlusMinor} />} onClick={e => redirectPage('create-recommendation')}>
                        Create Recommendation
                    </Button>
                }
            >
                {offers.map((offer, index) => {
                    return (
                        <Card sectioned key={index}>
                            <Stack alignment="center">
                                <Stack.Item fill>
                                    <DisplayText size="small">
                                        {offer.offerName}
                                        {offer.offerStatus === 1 && <Badge status="success">Active</Badge>}
                                    </DisplayText>
                                </Stack.Item>
                                <ButtonGroup>
                                    <Button onClick={e => changeStatus(offer.id)} loading={statusLoading === offer.id ? true : false}>
                                        {offer.offerStatus === 1 ? 'Deactivate' : 'Activate'}
                                    </Button>
                                    {offer.offerStatus !== 1 &&
                                        <Button primary onClick={e => redirectPage(`edit-recommendation/${offer.id}`)}>Edit</Button>
                                    }
                                    {offer.offerStatus !== 1 &&
                                        <Button destructive onClick={e => deleteOffer(offer.id)} loading={deleteLoding === offer.id ? true : false}>
                                            Delete
										</Button>
                                    }
                                    <Button primary onClick={e => redirectPage(`recommendation-analytics/${offer.id}`)}>Analytics</Button>
                                </ButtonGroup>
                            </Stack>
                        </Card>
                    )
                })}
                {offers.length === 0 && skeletonLoader === false &&
                    <EmptyOffer url="create-recommendation" buttonText="Create Recommendation" />
                }
                {skeletonLoader &&
                    <Loader />
                }
                <Tooltip tooltip={toolTip} />
            </Page>
        </div>
    );
}

