import React, { useCallback, useState, useEffect } from 'react';
import { Card, Button, Sheet, Scrollable, Icon, Link } from '@shopify/polaris';
import { redirectPage } from '../common/Helper';
import {
    MobileCancelMajorMonotone,
    MobileHamburgerMajorMonotone,
} from "@shopify/polaris-icons";
import AppMenu from './Menu';
import API from '../service/Api';
import { useGlobalPlanActionsContext } from '../context/GlobalContext';
import { getStoreName } from './Helper';
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { useLocation } from 'react-router-dom';

function TabHeader() {
    const [selected, setSelected] = useState(0);
    const location = useLocation();
    const setGlobalPlan = useGlobalPlanActionsContext()
    // const setToken = useGlobalTokenActionsContext();
    const shop = getStoreName();

    useEffect(() => {
        if (shop) {
            const config = { apiKey: 'f0ac34c864e5a626de695650ccba23ed', shopOrigin: shop };
            const shopifyapp = createApp(config);
            getSessionToken(shopifyapp).then(resp => {
                localStorage.setItem('storeToken', resp);
                API.get(`plans/active/plan`, {
                    headers: {
                        'Authorization': `Bearer ${resp}`
                    }
                }).then(response => {
                    setGlobalPlan(response.data.data);
                    localStorage.setItem('cartboostPlan', JSON.stringify(response.data.data));
                }).catch(err => {
                    console.log('err: ', err);
                });
            }).catch(err => {
                console.log('err: ', err);
            })
        }
    }, [shop, setGlobalPlan]);

    useEffect(() => {
        let tabNumber = 0;
        if (location.pathname.search('incart') >= 0) {
            tabNumber = 1;
        } else if (location.pathname.search('/popup') >= 0) {
            tabNumber = 2;
        } else if (location.pathname.search('recommendation') >= 0) {
            tabNumber = 3;
        } else if (location.pathname.search('sale') >= 0) {
            tabNumber = 4;
        } else if (location.pathname.search('timer') >= 0) {
            tabNumber = 5;
        } else if (location.pathname.search('analytics') >= 0) {
            tabNumber = 8;
        } else if (location.pathname.search('badge') >= 0) {
            tabNumber = 6;
        } else if (location.pathname.search('boost-bar') >= 0) {
            tabNumber = 7;
        } else if (location.pathname.search('faq') >= 0) {
            tabNumber = 10;
        }
        setSelected(tabNumber);
    }, [location]);

    const [sheetActive, setSheetActive] = useState(false);
    const toggleSheetActive = useCallback(
        () => setSheetActive((sheetActive) => !sheetActive),
        [],
    );

    return (
        <div className="cartboost-app-menu">
            <Card>
                <div className="mobile-menu flex-between">
                    <Link onClick={e => redirectPage('home')}>
                        <div className="logo-div-sec">
                            <img src={require('../assets/logo-2.jpg')} alt="" />
                        </div>
                    </Link>
                    <span onClick={toggleSheetActive} style={{ cursor: "pointer" }}>
                        <Icon source={MobileHamburgerMajorMonotone} />
                    </span>
                </div>
            </Card>

            <Sheet open={sheetActive} onClose={toggleSheetActive}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            borderBottom: '1px solid #DFE3E8',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1.6rem',
                            width: '100%',
                        }}
                    >
                        <div className="flex-between sidebar-menu">
                            <div className="logo-div-sec">
                                <img src={require('../assets/logo-2.jpg')} alt="Logo" />
                            </div>
                            <Button
                                accessibilityLabel="Cancel"
                                icon={MobileCancelMajorMonotone}
                                onClick={toggleSheetActive}
                                plain
                            />
                        </div>
                    </div>
                    <Scrollable style={{ height: '100%' }}>
                        <AppMenu screen="mobile" selected={selected} />
                    </Scrollable>
                </div>
            </Sheet>
        </div>
    )
}

export default TabHeader;

