import React from 'react';
import './App.css';
import routes from './routes';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom';

const PrivateRoute = ({ ...rest }) => {
	let offerMenus = [];
	if (localStorage.getItem('cartboostPlan')) {
		offerMenus = JSON.parse(localStorage.getItem('cartboostPlan'));
		offerMenus = JSON.parse(offerMenus.menus);
	}

	if (rest.authL === false || (rest.authL === true && offerMenus.indexOf(rest.name) !== -1)) {
		return (
			<Route {...rest} />
		)
	} else {
		return (
			<Redirect to={{ pathname: "/" }} />
		) 
	}
}


export default function App() {
	return (
		<Router>
			<div>
				<Switch>
					{routes.map((route, i) => {
						return <PrivateRoute key={i} {...route}  />
					})}
				</Switch>
			</div>
		</Router>
	);
}





