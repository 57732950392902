import React, { useEffect, useState } from 'react';
import { Card, Page, TextStyle, Button, Stack, DisplayText, ButtonGroup, Icon, Badge } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import EmptyOffer from '../EmptyOffer';
import Loader from '../../common/Loader';
import { PlusMinor, LockMajorMonotone } from '@shopify/polaris-icons';
import { redirectPage, upgradePlans, getStoreName } from '../../common/Helper';
import { useGlobalPlanContext } from '../../context/GlobalContext';


export default function TabsExample() {
	const [offers, setOffers] = useState([]);
	const [isLoading, setLoading] = useState(null);
	const [deleteLoding, setDeleteLoding] = useState(null);
	const [skeletonLoader, setSkeletonLoader] = useState(true);
	const [toolTip, setTooltip] = useState({
		active: false,
		content: null,
		error: false
	});
	const shop = getStoreName();
	const [activeUpsellsLimit, setActiveUpsellsLimit] = useState(-1);
	const [activeUpsells, setActiveUpsells] = useState(0);
	const plan = useGlobalPlanContext();
	useEffect(() => {
		if (plan) {
			setActiveUpsellsLimit(plan.active_upsells);
		}
	}, [plan])
	// get offers from db
	useEffect(() => {
		API.get(`popup-upsell/fetch`).then(response => {
			setOffers(response.data.data);
			const data = response.data.data;
			const activeUpsells = data.filter(x => x.offerStatus === 1);
			setActiveUpsells(activeUpsells.length);
			setSkeletonLoader(false);
		}).catch(err => {
			setSkeletonLoader(false);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	}, [shop]);

	const changeStatus = (offerId) => {
		setLoading(offerId);
		const allOffers = [...offers];
		const index = allOffers.findIndex(x => x.id === offerId);
		const url = allOffers[index].offerStatus === 0 ? 'activate' : 'deactivate';
		API.post(`popup-upsell/${url}/${offerId}`).then(response => {
			allOffers[index].offerStatus = allOffers[index].offerStatus === 0 ? 1 : 0;
			setOffers(allOffers);
			setLoading(null);
			setTooltip({
				content: "Status Changed Successfully",
				active: true,
				error: false
			});
		}).catch(err => {
			setLoading(null);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	};

	const deleteOffer = (id) => {
		setDeleteLoding(id);
		API.delete(`popup-upsell/${id}`).then(response => {
			setTooltip({
				content: "Offer Deleted Successfully",
				active: true,
				error: false
			});
			const allOffers = [...offers];
			const newOffers = allOffers.filter(x => x.id !== id);
			setOffers(newOffers);
			setDeleteLoding(null);
		}).catch(err => {
			setDeleteLoding(null);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	};

	const offerStatusButtons = (offer) => {
		if (activeUpsellsLimit !== -1 && offer.offerStatus === 0 && activeUpsells >= activeUpsellsLimit) {
			return (
				<div className="upgrade-plan-button">
					<Button onClick={e => upgradePlans(shop)} icon={<Icon source={LockMajorMonotone} color="blueDark" />}>Upgrade your plan to activate additional upsells</Button>
				</div>
			);
		}
		return (
			<Button onClick={e => changeStatus(offer.id)} loading={isLoading === offer.id ? true : false}>
				{offer.offerStatus === 1 ? 'Deactivate' : 'Activate'}
			</Button>
		);
	};

	return (
		<div className='upsells-outr'>
			<TabHeader />
			<Page
				title="Popup Upsells"
				primaryAction={
					<Button primary icon={<Icon source={PlusMinor} />} onClick={e => redirectPage('popup-upsells/create')}>
						Add new Popup Upsell
    				</Button>
				}
			>
				{offers.map((offer, index) => {
					const metaData = JSON.parse(offer.popupOptions);
					return (
						<Card sectioned key={index}>
							<Stack alignment="center">
								<Stack.Item fill>
									<DisplayText size="small">
										{offer.offerName}
										{offer.offerStatus === 1 && <Badge status="success">Active</Badge>}
									</DisplayText>
									<TextStyle variation="subdued">{metaData.offerDescription_meta}</TextStyle>
								</Stack.Item>
								<ButtonGroup>
									{offerStatusButtons(offer)}
									{offer.offerStatus !== 1 &&
										<Button primary onClick={e => redirectPage(`popup-upsells/edit/${offer.id}`)}>Edit</Button>
									}
									{offer.offerStatus !== 1 &&
										<Button destructive onClick={e => deleteOffer(offer.id)} loading={deleteLoding === offer.id ? true : false}>
											Delete
										</Button>
									}
									<Button primary onClick={e => redirectPage(`popup-analytics/${offer.id}`)}>Analytics</Button>
								</ButtonGroup>
							</Stack>
						</Card>
					)
				})}
				{offers.length === 0 && skeletonLoader === false &&
					<EmptyOffer url="popup-upsells/create" buttonText="Add new Popup Upsell" />
				}
				{skeletonLoader &&
					<Loader />
				}
				<Tooltip tooltip={toolTip} />
			</Page>
		</div>
	);
}

