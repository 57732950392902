
import axios from "axios";

export default axios.create({
    baseURL: "http://app.cartboostapp.com/cartboost/api/v1/",
    headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('storeToken')}`
    }
});
